import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/user/all");
  },
  details(id) {
    return Api().get(`/user/${id}`);
  },
  GetNew() {
    return Api().get("/user?sort_by=created_at&sort_type=-1");
  },
  //   getDetail(id) {
  //     return Api().get(`/user/${id}`);
  //   },
  //   GetOptionUserRole(role) {
  //     return Api().get(`/user/getuser?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/user/${id}`, data);
  },

  Add(data) {
    return Api().post(`/user/`, data);
  },
  Login(data) {
    return Api().post(`/user/login`, data);
  },
  Delete(id) {
    return Api().delete(`/user/${id}`);
  },
  DeleteMultiple(data) {
    return Api().post(`/user/all`, data);
  },
  //   UploadImage(id, data) {
  //     return Api().post(`user/upload/${id}`, data);
  //   },
};
