<template>
  <div
    class="
      surface-0
      flex
      align-items-center
      justify-content-center
      min-h-screen min-w-screen
      overflow-hidden
    "
  >
    <Toast />
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="text-center mb-5">
            <img
              src="layout/images/avatar.png"
              alt="Image"
              height="50"
              class="mb-3"
            />
            <div class="text-900 text-3xl font-medium mb-3">
              {{ username !== "" ? `Welcome, ${username}` : "Welcome Admin!" }}
            </div>
            <span class="text-600 font-medium">Sign in to continue</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2"
              >Email</label
            >
            <InputText
              id="email1"
              v-model="form.email"
              type="text"
              class="w-full mb-3"
              placeholder="Email"
              style="padding: 1rem"
            />

            <label
              for="password1"
              class="block text-900 font-medium text-xl mb-2"
              >Password</label
            >
            <Password
              id="password1"
              v-model="form.password"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              inputStyle="padding:1rem"
            ></Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <!-- <div class="flex align-items-center">
                <Checkbox
                  id="rememberme1"
                  v-model="checked"
                  :binary="true"
                  class="mr-2"
                ></Checkbox>
                <label for="rememberme1">Remember me</label>
              </div> -->
              <!-- <a
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: var(--primary-color)"
                >Forgot password?</a
              > -->
            </div>
            <Button
              :loading="isLoading"
              @click="login"
              label="Sign In"
              class="w-full p-3 text-xl"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from "../api/User";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      username: "",
      checked: false,
    };
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  mounted() {
    let username = this.$cookies.get("username");
    if (username) {
      this.username = username;
    }
  },
  methods: {
    async login() {
      try {
        this.isLoading = true;
        const response = await UserApi.Login(this.form);
        if (response.data.status === 200) {
          let user = jwt_decode(response.data.token);
          // localStorage.setItem("username", user.username);
          // localStorage.setItem("user", user);
          // localStorage.setItem("token", response.data.token, user.exp);

          this.$cookies.set("username", user.username);
          this.$cookies.set("user", user, user.exp);
          this.$cookies.set("token", response.data.token, user.exp);
          this.getUserDetails(user.id_user);
        } else {
          this.isLoading = false;
          this.$toast.add({
            severity: "error",
            summary: "Failed to login",
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserDetails(id) {
      try {
        const response = await UserApi.details(id);
        if (response.data.status === 200) {
          localStorage.setItem(
            "acl",
            JSON.stringify(response.data.data.role_id?.access_list)
          );
          this.$router.push("/admin/dashboard");
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.$cookies.remove("username");
          this.$cookies.remove("user");
          this.$cookies.remove("token");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>