import axios from "axios";
import BaseUrl from "./BaseURL";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default () => {
  let instance = axios.create({
    // send token
    headers: {
      Authorization: cookies.get("token") ? cookies.get("token") : null,
    },
    baseURL: BaseUrl.baseURL,
  });

  instance.interceptors.response.use(
    function (response) {
      if (response.data.status == 402) {
        cookies.remove("token");
        cookies.remove("user");
        localStorage.removeItem("acl");
        window.location = "/login";
      }
      if (response.data.status == 403) {
        cookies.remove("token");
        cookies.remove("user");
        localStorage.removeItem("acl");
        window.alert("UNAUTHORIZED");
        window.location = "/login";
      }

      response.data.status;
      // Any status code within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};

