import { createRouter, createWebHistory } from "vue-router";
// import jwtDecode from "jwt-decode";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/admin/dashboard",
    name: "dashboard",
    component: () => import("./pages/Dashboard/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/user-management/user",
    name: "user",
    component: () => import("./pages/User/User.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/user-management/role",
    name: "role",
    component: () => import("./pages/User/Role.vue"),
    meta: {
      requiresAuth: true,
      requiredSuperAdmin: true,
    },
  },
  {
    path: "/admin/setting/address",
    name: "setting-address",
    component: () => import("./pages/Setting/Address.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/promotion/membership",
    name: "membership",
    component: () => import("./pages/Promotion/Membership.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/promotion/discount",
    name: "discount",
    component: () => import("./pages/Promotion/Discount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/setting/menu",
    name: "menu",
    component: () => import("./pages/Menu/MenuList.vue"),
    meta: {
      requiresAuth: true,
      requiredSuperAdmin: true,
    },
  },
  {
    path: "/admin/setting/menu/sub-menu",
    name: "sub-menu",
    component: () => import("./pages/Menu/SubMenuList.vue"),
    meta: {
      requiresAuth: true,
      requiredSuperAdmin: true,
    },
  },
  {
    path: "/admin/content-management/email",
    name: "email",
    component: () => import("./pages/Email/Email.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/product",
    name: "product",
    component: () => import("./pages/Product/Product.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/product/add",
    name: "product_add",
    component: () => import("./pages/Product/Add.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/product/update/:id",
    name: "product_update",
    component: () => import("./pages/Product/Update.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/attribute",
    name: "product_attribute",
    component: () => import("./pages/Attribute/Attribute.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/attribute/add",
    name: "product_attribute_add",
    component: () => import("./pages/Attribute/Add.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/product-management/attribute/update/:id",
    name: "product_attribute_update",
    component: () => import("./pages/Attribute/Update.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/transaction-management/transaction",
    name: "transaction",
    component: () => import("./pages/Transaction/Transaction.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/transaction-management/transaction/:id/update",
    name: "transaction_update",
    component: () => import("./pages/Transaction/Detail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    redirect: "/admin/dashboard",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (cookies.get("user") === null) {
//       next({
//         path: "/login",
//         params: { nextUrl: to.fullPath },
//       });
//     } else {
//       next();
//     }
//   } else {
//     console.log("toelse");
//     next();
//   }
// });

router.beforeEach((to) => {
  // let get_token = localStorage.getItem("token");
  // let token = get_token ? get_token : "";
  const token = cookies.get("token");

  if (to.meta.requiresAuth && !token) {
    return {
      path: "/login",
      query: { redirect: to.fullPath },
    };
  }

  let user = cookies.get("user");
  if (to.meta.requiredSuperAdmin && user?.role_id?.name !== "super_admin") {
    return {
      path: "/admin/dashboard",
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
