<template>
  <div class="layout-topbar">
    <Toast />
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" src="./assets/images/logo.svg" />
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li style="display: flex; align-items: center">
        <span>{{ $cookies.get("username") }}</span>
      </li>
      <li>
        <button
          class="p-link layout-topbar-button"
          @click="showModalLogout = true"
        >
          <i class="pi pi-sign-out"></i>
          <span>Profile</span>
        </button>
      </li>
    </ul>
    <Dialog
      v-model:visible="showModalLogout"
      :style="{ width: '450px' }"
      header="Logout Confirmation ?"
      :modal="true"
    >
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure want to logout ?</span>
      </div>
      <template #footer>
        <Button
          @click="showModalLogout = !showModalLogout"
          label="No"
          icon="pi pi-times"
          class="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          :loading="isLoading"
          @click="handleLogout"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModalLogout: false,
    };
  },
  methods: {
    handleLogout() {
      this.$cookies.remove("user");
      this.$cookies.remove("token");
      localStorage.removeItem("acl");
      this.$router.push("/login");
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo-dark.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>