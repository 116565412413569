<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig
      :layoutMode="layoutMode"
      @layout-change="onLayoutChange"
      @change-theme="changeTheme"
    />
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
// import MenuApi from "./api/Menu";
// import jwtDecode from "jwt-decode";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    async getMenu() {
      try {
        const token = this.$cookies.get("token");
        let get_access_list = localStorage.getItem("acl");

        if (!token || !get_access_list) {
          return;
        }

        let access_list = JSON.parse(get_access_list);

        // console.log(access_list);
        // const response = await MenuApi.Get();

        // if (response.data.status === 200) {
        //   let menuList = [];
        //   let menu = response.data.data.sort((a, b) =>
        //     a.order > b.order ? 1 : -1
        //   );

        //   for (let i = 0; i < menu.length; i++) {
        //     let temp_menu = {
        //       label: "",
        //       icon: "",
        //       items: [],
        //     };

        //     temp_menu = {
        //       label: menu[i].name,
        //       icon: menu[i].icon,
        //       items: [],
        //     };

        //     menu[i].sub_menu.map((sub) => {
        //       temp_menu.items.push({
        //         label: sub.title,
        //         icon: sub.icon,
        //         to: `/admin${menu[i].route}${sub.route}`,
        //       });
        //     });

        //     menuList.push(temp_menu);
        //   }

        //   this.menu = menuList;

        //   console.log("menuList", menuList);
        // }

        let temp_access_list = [];

        // get all parent and filter same val
        access_list.map((data) => {
          let isExist = temp_access_list.filter((prevVal) => {
            return prevVal.label === data.menu_id.name;
          });

          // prevent same value
          if (!isExist.length) {
            temp_access_list.push({
              label: data.menu_id.name,
              icon: null,
              items: [],
              order: data.menu_id.order,
            });
          }
        });

        // order menu by order
        temp_access_list = temp_access_list.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );

        // find parent and push children to parent data.menu_id.name
        access_list.map((data) => {
          let parentIndex = temp_access_list.findIndex(
            (prevVal) => prevVal.label === data.menu_id.name
          );

          temp_access_list[parentIndex].items.push({
            label: data.title,
            icon: data.icon,
            to: `/admin${data.menu_id.route}${data.route}`,
          });
        });

        this.menu = temp_access_list;
      } catch (error) {
        console.log(error);
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
